import {StatusTag} from '@prescriberpoint/ui';
import {FC} from 'react';
import {useUserAgentContext} from '@/context';

interface IStatusTagNeonProps {
  label: string;
}

const StatusTagNeon: FC<IStatusTagNeonProps> = ({label}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  return (
    <StatusTag
      label={label}
      size={isMobileOrTablet ? 'sm' : 'lg'}
      type='custom'
      casing='default'
      className='text-bold bg-neon-gradient text-neutral-dark'
      iconContainerClassName='rounded-full bg-white h-4.5 w-4.5'
      iconClassName='w-3'
      icon='CheckmarkFilled'
      showIcon
    />
  );
};

export default StatusTagNeon;
