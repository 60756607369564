import {Modal, Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import {useCoverageRestrictionsContext} from '@/context';

interface ICoverageModalReloadProps {
  onConfirm: () => void;
}

const CoverageModalReload: FC<ICoverageModalReloadProps> = ({onConfirm}) => {
  const {showCoverageModalReload, setShowCoverageModalReload} =
    useCoverageRestrictionsContext();

  const handleOnClose = () => {
    setShowCoverageModalReload(false);
  };

  const handleOnConfirm = () => {
    setShowCoverageModalReload(false);
    onConfirm();
  };

  return (
    <Modal
      title='Confirm'
      showCloseButton
      isOpen={showCoverageModalReload}
      onConfirm={handleOnConfirm}
      onCancel={handleOnClose}
      closeOnClickOutside
      closeModal={handleOnClose}>
      <Text as='body-md'>
        Restart your search? Current progress will be reset.
      </Text>
    </Modal>
  );
};

export default CoverageModalReload;
