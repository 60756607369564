import {Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import StatusTagNeon from './StatusTagNeon';
import {COVERAGE_V3} from '@/constants/flags';
import {useFlag, useUserAgentContext} from '@/context';

interface ICoverageHeaderProps {
  drugName: string;
}

const CoverageHeader: FC<ICoverageHeaderProps> = ({drugName}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  const showCoverageV3 = useFlag(COVERAGE_V3);

  if (isMobileOrTablet) {
    return (
      <div className='flex flex-col space-y-3 md:space-y-6'>
        <Text as='headline-sm' weight='bold'>
          Get Your Patient on {drugName}
        </Text>
        {showCoverageV3 ? (
          <Text as='body-md' size='md'>
            See your patient&apos;s specific prior authorization requirements
            including coverage restrictions and step therapies
          </Text>
        ) : (
          <div className='flex flex-row flex-wrap items-center justify-center space-x-1 space-y-0.5'>
            <StatusTagNeon label='Find restrictions' />
            <StatusTagNeon label='See step therapies' />
            <StatusTagNeon label='Compare coverage options' />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='flex flex-col space-y-3 md:space-y-6 md:text-center'>
      <Text as='headline-lg' weight='bold'>
        Check Coverage Restrictions
      </Text>
    </div>
  );
};

export default CoverageHeader;
