import {Text, Link} from '@prescriberpoint/ui';
import {FC} from 'react';
import {useCoverageRestrictionsContext, useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';

const CoverageEmployerSearchSummary: FC = () => {
  const {
    coverageRestrictionTitle,
    setShowCoverageModalReload,
    selectedIndication,
    selectedDosage,
  } = useCoverageRestrictionsContext();

  const {isMobileOrTablet} = useUserAgentContext();
  const {drugName} = useCurrentDrug();

  return (
    <>
      <div className='flex flex-col'>
        <Text
          as={isMobileOrTablet ? 'headline-sm' : 'headline-md'}
          weight='bold'>
          {`${drugName} for ${selectedIndication!.text} (${
            selectedDosage!.text
          })`}
        </Text>
        <Text as='body-md' weight='semibold'>
          {coverageRestrictionTitle}
        </Text>
      </div>

      <Link
        id='details_action_button'
        data-testid='details_action_button'
        label='Try another search'
        type='underline'
        onClick={() => setShowCoverageModalReload(true)}
        className='mt-5 block md:mt-3'
        linkSize='xl'
      />
    </>
  );
};

export default CoverageEmployerSearchSummary;
