import {FC} from 'react';
import FAPrograms from './FAPrograms';
import ProgramSection from './ProgramSection';
import {SectionNew} from '@/components';
import {
  COPAY_PROGRAM,
  PATIENT_ASSISTANCE_PROGRAM,
  FOUNDATION_PROGRAM,
  FINANCIAL_ASSISTANCE_DETAILS,
} from '@/constants/financialAssistance';
import {FINANCIAL_ASSISTANCE_AFFORDABILITY} from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';
import {FINANCIAL_ASSISTANCE_SECTION_IDS} from '@/data';
import {useCurrentDrug} from '@/hooks';
import {EnhancedContentLinkDto, IEnhancedContent} from '@/models';
import {
  getCopayProgramDetails,
  getPatientAssistancePrice,
  getPatientProgramDetails,
  getCopayPrice,
  getFoundationPrograms,
  replaceVariablesInStr,
  formatUSD,
} from '@/utils';

const {title, drugDetail} = FINANCIAL_ASSISTANCE_DETAILS;

const {financialAssistance: sectionId} = SECTION_IDS;

export interface IFinancialAssistanceProps {
  enhancedContent?: IEnhancedContent;
  resources: EnhancedContentLinkDto[];
  retailPrice?: number;
}

const FinancialAssistance: FC<IFinancialAssistanceProps> = ({
  resources,
  enhancedContent,
  retailPrice,
}) => {
  const {drugName} = useCurrentDrug();
  const showPrograms = useFlag(FINANCIAL_ASSISTANCE_AFFORDABILITY);

  const {
    copaySavingsCardProgram,
    foundationAssistanceProgram,
    patientAssistanceProgram,
    financialAssistanceProgram,
  } = enhancedContent ?? {};

  const copayProgramSection = copaySavingsCardProgram ? (
    <ProgramSection
      title={COPAY_PROGRAM.name}
      chipInfo={getCopayPrice(copaySavingsCardProgram)}
      programs={COPAY_PROGRAM.programs}
      details={getCopayProgramDetails(copaySavingsCardProgram)}
      resources={resources}
      sectionId={FINANCIAL_ASSISTANCE_SECTION_IDS.copay}
    />
  ) : null;
  const patientAssistanceProgramSection = patientAssistanceProgram ? (
    <ProgramSection
      title={PATIENT_ASSISTANCE_PROGRAM.title}
      chipInfo={getPatientAssistancePrice(patientAssistanceProgram)}
      programs={PATIENT_ASSISTANCE_PROGRAM.programs}
      details={getPatientProgramDetails(patientAssistanceProgram)}
      resources={resources}
      sectionId={FINANCIAL_ASSISTANCE_SECTION_IDS.copay}
      // Copay is an equivalent of Patient Assistance Program
    />
  ) : null;

  const regex = /\s\$\d*\.\d*\s/;
  const [detailBeforePrice, detailAfterPrice] = replaceVariablesInStr(
    drugDetail,
    {drugName},
  ).split(regex);

  return (
    <SectionNew
      className='flex w-full flex-col'
      id={sectionId}
      data-testid={sectionId}
      title={title}
      headingCta={
        showPrograms ? <FAPrograms retailPrice={retailPrice} /> : null
      }
      subtitle={
        retailPrice
          ? `${detailBeforePrice} ${formatUSD(Math.round(retailPrice))} ${detailAfterPrice}`
          : undefined
      }>
      {/* This is implemented this way in order to not break anything inside PDP,
          on the near future, copayProgramSection and foundationProgramSection
          will be removed from enhanced content and only financialAssistanceProgram
          will be used
        */}
      <div className='flex flex-col gap-y-6'>
        {financialAssistanceProgram ? (
          financialAssistanceProgram.programType === 'Patient Assistance' ? (
            patientAssistanceProgramSection
          ) : (
            copayProgramSection
          )
        ) : (
          <>
            {copayProgramSection}
            {patientAssistanceProgramSection}
          </>
        )}
        {foundationAssistanceProgram ? (
          <ProgramSection
            title={FOUNDATION_PROGRAM.name}
            chipInfo={FOUNDATION_PROGRAM.price}
            programs={getFoundationPrograms(foundationAssistanceProgram)}
            requirements={foundationAssistanceProgram.requirements}
            resources={resources}
            sectionId={FINANCIAL_ASSISTANCE_SECTION_IDS.foundation}
          />
        ) : null}
      </div>
    </SectionNew>
  );
};
export default FinancialAssistance;
