import clsx from 'clsx';
import {FC} from 'react';
import CoverageEmployerSearchSummary from './CoverageEmployerSearchSummary';
import CoverageLoader from './CoverageLoader';
import CoveragePayerForm from './CoveragePayerForm';
import {COVERAGE_V3} from '@/constants/flags';
import {
  useCoverageRestrictionsContext,
  useFlag,
  useUserAgentContext,
} from '@/context';

interface ICoverageSummarySearchProps {
  onPdp: boolean;
}

const CoverageSummarySearch: FC<ICoverageSummarySearchProps> = ({onPdp}) => {
  const showCoverageV3 = useFlag(COVERAGE_V3);
  const {isSubmitting, result, showEmployerForm, showForm} =
    useCoverageRestrictionsContext();
  const {isMobileOrTablet} = useUserAgentContext();

  if (isSubmitting && !result) {
    return <CoverageLoader duration={7000} />;
  }

  return (
    <div
      className={clsx('w-full min-w-full space-y-4 rounded-xl', {
        'bg-theme-lighter-alt p-4': (result || !showForm) && isMobileOrTablet,
        'rounded-2xl bg-theme-lighter-alt p-8':
          !result && !showForm && !isMobileOrTablet && onPdp,
      })}>
      {showEmployerForm && showCoverageV3 ? (
        <CoverageEmployerSearchSummary />
      ) : (
        <CoveragePayerForm onPdp={onPdp} />
      )}
    </div>
  );
};

export default CoverageSummarySearch;
