import {customEvent} from '@prescriberpoint/ui';
import {FC, FormEvent} from 'react';
import {useCookies} from 'react-cookie';
import {coverageSearch} from './coverageSearch';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import {NPI_COOKIE} from '@/constants/cookies';
import {CEVENT_COVERAGE_SEARCH} from '@/constants/gtm';
import {useCoverageRestrictionsContext} from '@/context/CoverageRestrictionsContext';
import {useCurrentDrug} from '@/hooks';
import {useStepTherapyLogMutation} from '@/mutations';

interface IFormProps {
  onPdp?: boolean;
}

const Form: FC<IFormProps> = ({onPdp = false}) => {
  const {
    setFormExpanded,
    selectedIndication,
    selectedDosage,
    setCoverageRestrictionTitle,
    setSubmittedCoverage,
    setResult,
    setIsSubmitting,
    isSubmitting,
    healthPlan,
    planType,
    insuranceChannel,
    showForm,
    generalData,
    validateForm,
  } = useCoverageRestrictionsContext();

  const {setCurrentStep} = generalData;
  const {setId, drugName, genericName} = useCurrentDrug();
  const [cookies] = useCookies([NPI_COOKIE]);
  const {mutateAsync: stepTherapyMutate} = useStepTherapyLogMutation();

  const handleCoverageSearch = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      validateForm() &&
      healthPlan &&
      planType &&
      insuranceChannel &&
      selectedIndication &&
      selectedDosage &&
      !isSubmitting
    ) {
      setIsSubmitting(true);

      const mcoName = healthPlan.value;
      const planTypeName = planType.text;
      const channelName = insuranceChannel.text;
      const indicationId = selectedIndication.id.toString();
      const dosageId = selectedDosage.id.toString();
      const dataToSubmit = {
        mco: healthPlan.id,
        planType: planType.key,
        channel: insuranceChannel.key,
        indicationId,
        setId,
        rxcui: dosageId,
      };
      const coverageResult = await coverageSearch(dataToSubmit);

      customEvent(CEVENT_COVERAGE_SEARCH, {
        employer: '',
        mco: dataToSubmit.mco,
        channel: dataToSubmit.channel,
        planType: dataToSubmit.planType,
        npiValue: cookies?.npi ?? null,
      });
      const formularyId = coverageResult?.topFormularyDetail?.FormularyId;

      if (coverageResult && formularyId) {
        const {
          priorAuthorization,
          quantityLimit,
          stepTherapy,
          specialtyPharmacy,
        } = coverageResult.coverageInsights;
        const carrier = coverageResult.coverageRestrictions;

        setResult({
          payerName: coverageResult.mcoCoverages[0].MCO,
          planType: planTypeName,
          channel: channelName,
          carrier,
          formularyId,
          hasPaRestrictions: priorAuthorization,
          hasStepTherapy: stepTherapy,
          hasQuantityLimits: quantityLimit,
          hasSpecialtyPharmacy: specialtyPharmacy,
        });
      } else {
        setResult({
          payerName: mcoName,
          channel: channelName,
          planType: planTypeName,
        });
      }
      const stepTherapyMutateData = {
        formularyIds:
          coverageResult?.formularies.map((f) => +f.formularyId) ?? undefined,
        brand: drugName,
        indication: selectedIndication.text,
        indicationId: `${selectedIndication.id}`,
        dosage: selectedDosage.text,
        dosageId: selectedDosage.id,
        npi: cookies?.npi ?? '',
        chosenFormularyId: formularyId ?? null,
        carrier: mcoName,
        insuranceChannel: channelName,
        genericName,
      };

      if (cookies?.npi) {
        await stepTherapyMutate(stepTherapyMutateData);
      }

      setSubmittedCoverage(stepTherapyMutateData);
      setCoverageRestrictionTitle(`${mcoName} - ${planTypeName}`);
      setIsSubmitting(false);
      setFormExpanded(false);
      setCurrentStep(2);
    }
  };

  const shouldShowSecondStep = showForm && healthPlan;

  return (
    <form
      onSubmit={handleCoverageSearch}
      data-testid='employer-coverage-form'
      className='flex flex-col space-y-3 md:space-y-3'>
      <FirstStep onPdp={onPdp}/>
      {shouldShowSecondStep ? <SecondStep /> : null}
    </form>
  );
};

export default Form;
