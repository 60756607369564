import {Icon, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC} from 'react';

interface ICoverageNonResultItemProps {
  error?: boolean;
  text: string;
}

const CoverageNonResultItem: FC<ICoverageNonResultItemProps> = ({
  error = true,
  text,
}) => (
  <div className="flex flex-row space-x-2">
    <div
      className={clsx(
        'rounded-full w-4.5 h-4.5 flex items-center justify-center',
        error ? 'bg-error-fill' : 'bg-theme-lighter',
      )}>
      <Icon
        name={error ? 'Cancel' : 'CheckmarkFilled'}
        className="text-neutral-dark !w-2.5"
      />
    </div>
    <Text as="title-md">{text}</Text>
  </div>
);

export default CoverageNonResultItem;
