import {Dropdown, Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import {IndicationOption} from '@/interfaces/patientIndication';
export interface IIndicationDosageSelectProps {
  indications: IndicationOption[];
  dosages: IndicationOption[];
  selectedIndication?: IndicationOption;
  selectedDosage?: IndicationOption;
  onSelectIndication: (value: IndicationOption) => void;
  onSelectDosage: (value: IndicationOption) => void;
  disabled?: boolean;
  dropdownSize?: 'md' | 'lg' | 'xl';
}

const IndicationDosageSelect: FC<IIndicationDosageSelectProps> = ({
  indications,
  dosages,
  selectedIndication,
  selectedDosage,
  onSelectIndication,
  onSelectDosage,
  disabled,
  dropdownSize = 'xl',
}) => (
  <div className='flex w-full max-w-full flex-col items-center space-y-3 md:grid md:flex-none md:grid-cols-2 md:gap-4 md:space-y-0'>
    <div className='flex w-full flex-col space-y-1 md:w-auto'>
      <Text weight='bold' as='body-sm'>
        Indication
      </Text>
      <Dropdown
        id='indication_dropdown'
        placeholder='Enter indication'
        selectedItem={selectedIndication}
        handleChange={(value) => {
          onSelectIndication(value as IndicationOption);
        }}
        buttonClassName='!rounded-lg flex-1'
        listClassName='z-max'
        options={indications}
        disabled={disabled}
        variant='text'
        size={dropdownSize}
        className='max-w-full'
      />
    </div>
    <div className='flex w-full flex-col space-y-1 md:w-auto'>
      <Text weight='bold' as='body-sm'>
        Dosage
      </Text>
      <Dropdown
        id='dosage_dropdown'
        placeholder='Enter dosage'
        selectedItem={selectedDosage}
        handleChange={(value) => {
          onSelectDosage(value as IndicationOption);
        }}
        buttonClassName='!rounded-lg flex-1'
        listClassName='z-max'
        options={dosages}
        disabled={disabled}
        size={dropdownSize}
        variant='text'
        className='max-w-full'
      />
    </div>
  </div>
);

export default IndicationDosageSelect;
