import {Button, customEvent, Dropdown, Loader, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useCallback, useMemo} from 'react';
import IndicationDosageSelect from './IndicationDosageSelect';
import {
  CEVENT_COVERAGE_CHANNEL_SELECTED,
  CEVENT_COVERAGE_INSURANCE_TYPE_SELECTED,
} from '@/constants';
import {useCoverageRestrictionsContext} from '@/context/CoverageRestrictionsContext';
import {useCurrentDrug} from '@/hooks';
import {IndicationOption} from '@/interfaces/patientIndication';
import {useCoverageFilters} from '@/queries';

const SecondStep: FC = () => {
  const {
    drugIndications,
    drugDosages,
    result,
    selectedIndication,
    selectedDosage,
    setSelectedIndication,
    setSelectedDosage,
    isSubmitting,
    healthPlan,
    isLoadingPatientIndication,
    insuranceChannel,
    setPlanType,
    setInsuranceChannel,
    setCoverageRestrictionTitle,
    planType,
    setResult,
    generalData,
    formErrors,
    resetFormErrors,
  } = useCoverageRestrictionsContext();

  const {setCurrentStep} = generalData;
  const {drugName} = useCurrentDrug();

  const {data: coverageFilters, isFetching: coverageFiltersLoading} =
    useCoverageFilters(`${healthPlan?.id}`, {
      enabled: !!healthPlan,
    });

  const nonMcoFieldsLoading =
    isLoadingPatientIndication ||
    coverageFiltersLoading ||
    !drugIndications ||
    !drugDosages;

  const channelFilters = useMemo(
    () => coverageFilters?.filters,
    [coverageFilters],
  );

  const resetOnChange = useCallback(() => {
    if (result !== undefined) {
      setResult(undefined);
      setCurrentStep(1);
    }
  }, [result, setResult, setCurrentStep]);

  const planTypeFilters = useMemo(
    () =>
      coverageFilters?.filters?.find(
        (filter) => filter.key === insuranceChannel?.key,
      )?.plans,
    [coverageFilters, insuranceChannel],
  );

  const onInsuranceTypeChange = useCallback(
    (value: any) => {
      if (insuranceChannel !== value) {
        resetOnChange();
        setPlanType(undefined);
        setInsuranceChannel(value);
        resetFormErrors();
        setCoverageRestrictionTitle('');

        customEvent(CEVENT_COVERAGE_CHANNEL_SELECTED, {
          drugBrand: drugName,
          channel: value.text,
          mco: healthPlan?.value,
        });
      }
    },
    [
      insuranceChannel,
      resetOnChange,
      setPlanType,
      setInsuranceChannel,
      resetFormErrors,
      setCoverageRestrictionTitle,
      drugName,
      healthPlan?.value,
    ],
  );

  const onPlanTypeChange = useCallback(
    (value: any) => {
      if (planType !== value) {
        resetOnChange();
        setPlanType(value);
        setCoverageRestrictionTitle('');
        resetFormErrors();

        customEvent(CEVENT_COVERAGE_INSURANCE_TYPE_SELECTED, {
          drugBrand: drugName,
          mco: healthPlan?.value,
          channel: insuranceChannel?.text,
          planType: value?.text,
        });
      }
    },
    [
      planType,
      resetOnChange,
      setPlanType,
      setCoverageRestrictionTitle,
      resetFormErrors,
      drugName,
      healthPlan?.value,
      insuranceChannel?.text,
    ],
  );

  const handleSelectIndication = (indication: IndicationOption | undefined) => {
    resetOnChange();
    setSelectedIndication(indication);
  };

  const handleSelectDosage = (dosage: IndicationOption | undefined) => {
    resetOnChange();
    setSelectedDosage(dosage);
  };

  const hasErrors = !!formErrors;

  return (
    <div className='flex flex-col space-y-5'>
      {nonMcoFieldsLoading ? (
        <div className='flex h-full w-full content-center justify-center'>
          <Loader spinnerSize='sm' />
        </div>
      ) : (
        <div className='flex flex-col space-y-3'>
          <div className='flex w-full flex-col gap-y-3 md:grid md:flex-none md:grid-cols-2 md:gap-4'>
            <div className='flex-1 space-y-1'>
              <Text weight='bold' as='body-sm'>
                Channel
              </Text>
              <Dropdown
                id='channel_dropdown'
                options={channelFilters ?? []}
                selectedItem={insuranceChannel}
                placeholder='Channel'
                buttonClassName={clsx('!rounded-lg', {
                  'border-error': formErrors?.channel,
                })}
                listClassName='text-neutral-dark notes-scrollbar'
                handleChange={onInsuranceTypeChange}
                disabled={isSubmitting}
                variant='text'
                size='lg'
              />
            </div>
            <div className='flex-1 space-y-1'>
              <Text weight='bold' as='body-sm'>
                Plan Type
              </Text>
              <Dropdown
                id='plan_type_dropdown'
                handleChange={onPlanTypeChange}
                selectedItem={planType}
                placeholder='Plan'
                buttonClassName={clsx('!rounded-lg', {
                  'border-error':
                    insuranceChannel && !isSubmitting && formErrors?.planType,
                })}
                listClassName='text-neutral-dark notes-scrollbar'
                disabled={!insuranceChannel || isSubmitting}
                options={planTypeFilters ?? []}
                variant='text'
                size='lg'
              />
            </div>
          </div>
          <IndicationDosageSelect
            indications={drugIndications}
            dosages={drugDosages}
            selectedIndication={selectedIndication}
            selectedDosage={selectedDosage}
            onSelectIndication={handleSelectIndication}
            onSelectDosage={handleSelectDosage}
            disabled={isSubmitting}
            dropdownSize='lg'
          />
          {hasErrors ? (
            <Text as='body-sm' className='text-error'>
              {formErrors?.channel ?? formErrors?.planType}
            </Text>
          ) : null}
        </div>
      )}
      <Button
        id='check_coverage'
        testId='check_coverage_btn'
        type='submit'
        stretched
        size='lg'
        className={clsx({'md:!mt-3': hasErrors})}>
        Check Coverage
      </Button>
    </div>
  );
};

export default SecondStep;
