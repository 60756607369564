import {ToolButton} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC, useCallback, useMemo} from 'react';
import {CTA_DATA} from './data';
import {CtaSectionType} from './models';
import {useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import useUrlsData, {PageType} from '@/hooks/useUrlsData';

interface ICtaSectionProps {
  className?: ClassValue;
  coverage?: boolean;
  interactions?: boolean;
  financialAssistance: boolean;
  priorAuthorization: boolean;
  patientEducation: boolean;
}

const CtaSection: FC<ICtaSectionProps> = ({
  className,
  coverage = false,
  interactions = false,
  financialAssistance = false,
  priorAuthorization = false,
  patientEducation = false,
}) => {
  const {slug, setId} = useCurrentDrug();
  const {isMobileOrTablet} = useUserAgentContext();
  const {getUrlByPage} = useUrlsData(slug as string);

  const ctas: {name: string; status: boolean}[] = useMemo(
    () => [
      {name: 'coverage', status: coverage},
      {name: 'interactions', status: interactions},
      {name: 'financialAssistance', status: financialAssistance},
      {name: 'priorAuthorization', status: priorAuthorization},
      {name: 'patientEducation', status: patientEducation},
    ],
    [
      coverage,
      interactions,
      financialAssistance,
      priorAuthorization,
      patientEducation,
    ],
  );

  const hasCta = ctas.some((cta) => cta.status);
  const ctaQuantity = ctas.filter((cta) => cta.status).length;
  const isNonAvailableSection = (section: CtaSectionType) =>
    ctas.some((cta) => cta.name === section && !cta.status);

  const handleOnClick = useCallback(
    (section: CtaSectionType) => {
      const anchor = CTA_DATA[section]?.anchor;
      if (anchor) {
        scrollToSection(anchor);
      } else {
        const url = getUrlByPage(section as PageType);
        if (url) {
          window.location.href = url;
        }
      }
    },
    [getUrlByPage],
  );

  const scrollToSection = (anchor: string) => {
    const element = document.getElementById(anchor);
    element?.scrollIntoView({behavior: 'smooth', block: 'start'});
  };

  if (!hasCta) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex w-full flex-row flex-wrap items-center justify-between gap-3 md:flex-nowrap md:justify-center md:gap-4 md:self-stretch',
        className,
      )}>
      {Object.keys(CTA_DATA).map((section: string, index: number) => {
        const text = CTA_DATA[section as CtaSectionType].text;
        let textWithoutVars = text;
        textWithoutVars = isMobileOrTablet
          ? textWithoutVars.replaceAll('\n', ' ')
          : textWithoutVars;
        const isMobileWithFiveCtasHorizontal =
          ((index >= 2 && ctaQuantity === 5) || ctaQuantity !== 5) &&
          isMobileOrTablet;

        if (isNonAvailableSection(section as CtaSectionType)) {
          return null;
        }

        return (
          <ToolButton
            key={`cta_tool_button_${textWithoutVars}`}
            text={textWithoutVars}
            IconComponent={CTA_DATA[section as CtaSectionType].icon}
            onClick={() => handleOnClick(section as CtaSectionType)}
            className={clsx('w-full max-w-full md:h-[104px] md:grow', {
              '!w-[calc(50%-6px)]': !isMobileWithFiveCtasHorizontal,
            })}
            orientation={
              isMobileWithFiveCtasHorizontal ? 'horizontal' : 'vertical'
            }
            outline
          />
        );
      })}
    </div>
  );
};

export default CtaSection;
