import {Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC} from 'react';
import CoveragePriorAuthRequirementsItem from './CoveragePriorAuthRequirementsItem';
import {useUserAgentContext} from '@/context';
import {ICoverageCarrierPaRestriction} from '@/models';
import {PARestrictionModel} from '@/models/coverageModel';

export interface ICoveragePriorAuthRequirementsProps {
  className?: ClassValue;
  requirements: ICoverageCarrierPaRestriction[] | PARestrictionModel[];
}

const CoveragePriorAuthRequirements: FC<
  ICoveragePriorAuthRequirementsProps
> = ({className, requirements}) => {
  const {isMobileOrTablet} = useUserAgentContext();

  if (requirements.length === 0) {
    return null;
  }

  return (
    <div className={clsx('flex flex-col space-y-6 md:space-y-3', className)}>
      <Text as={isMobileOrTablet ? 'title-md' : 'title-lg'} weight="extrabold">
        PA Requirements
      </Text>

      <ul className="columns-1 md:columns-2 md:gap-6 list-none p-0">
        {requirements.map(({key, value}) => (
          <li
            className="first:pt-0 last:pb-0 md:first:pt-3 md:last:pb-3 py-3 border-0 border-b border-solid border-neutral-light last:border-transparent"
            key={`coverage-prior-auth-req-item-${key}-${value}`}>
            <CoveragePriorAuthRequirementsItem
              title={key}
              description={value}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
export default CoveragePriorAuthRequirements;
