import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {FC, memo} from 'react';
import Sections from './Sections';
import PdpHeaderV1 from '@/components/PdpHeaderV1';
import SignUpForUpdates from '@/components/SignUpForUpdates';
import * as FLAGS from '@/constants/flags';
import {useFlag} from '@/context';
import {useAutoOpenChatBox, useCurrentDrug, useRenderInIFrame} from '@/hooks';
import {PageContentLayout} from '@/layouts';

const CTAMenu = dynamic(() => import('./CTAMenu'));

export interface ProductDetailProps {}

const ProductDetail: FC<ProductDetailProps> = () => {
  const {drugName, genericName} = useCurrentDrug();
  const pdpIsEmbedded = useRenderInIFrame();

  const showSignUpDrugUpdates =
    useFlag(FLAGS.SIGNUP_DRUG_UPDATE) &&
    drugName === 'Cibinqo' &&
    !pdpIsEmbedded; // this is only being rendered for Cibinqo;

  const showHeaderV2 = useFlag(FLAGS.SHOW_V2_PPT_DESIGN);
  const showCTA = !pdpIsEmbedded && !showHeaderV2;

  useAutoOpenChatBox();
  return (
    <div className={clsx('w-full bg-neutral-lighter-alt')}>
      <PageContentLayout>
        <div
          className={clsx(
            'min-h-page-content bg-neutral-lighter-alt px-4 md:px-0',
            {'px-6 pt-4': pdpIsEmbedded},
          )}>
          <PdpHeaderV1 drugName={drugName} genericName={genericName} />
          {showSignUpDrugUpdates ? <SignUpForUpdates /> : null}
          <div className='divide-x-0 divide-y divide-solid divide-gray-200'>
            <Sections />
          </div>
        </div>
        {showCTA ? <CTAMenu /> : null}
      </PageContentLayout>
    </div>
  );
};

export {default as OTCProductDetail} from './OTCProductDetail';
export default memo(ProductDetail);
