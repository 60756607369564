import {Text} from '@prescriberpoint/ui';
import ActionListItemWithShare from '@/components/ActionListItemWithShare';
import {EnhancedContentLinkDto} from '@/models';

interface ResourceListProps {
  readonly resources: EnhancedContentLinkDto[];
}

export default function ResourceList({resources}: ResourceListProps) {
  return (
    <div className='flex flex-col gap-y-2'>
      <Text as='body-md' weight='extrabold'>
        Forms
      </Text>
      <ul className='relative list-none divide-x-0 divide-y divide-solid divide-neutral-lighter rounded-lg border border-solid border-neutral-light px-0'>
        {resources.map((resource) => (
          <ActionListItemWithShare
            key={'resource-' + resource.title}
            resource={resource}
            type='open'
          />
        ))}
      </ul>
    </div>
  );
}
