import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {FC, memo} from 'react';
import Header from './Header';
import Sections from './Sections';
import SignUpForUpdates from '@/components/SignUpForUpdates';
import * as FLAGS from '@/constants/flags';
import {useFlag} from '@/context';
import {
  useAutoOpenChatBox,
  useCurrentDrug,
  useCurrentSlug,
  useEnhanced,
  useIsCoverageToolAvailable,
  useRenderInIFrame,
  useSectionsStatus,
} from '@/hooks';
import PageContentLayoutNew from '@/layouts/PageContentLayout/PageContentLayoutNew';
import {useInteractionsByDrug} from '@/queries';

const CTAMenu = dynamic(() => import('@/modules/ProductDetailPage/CTAMenu'));

const ProductDetail: FC = () => {
  const {setId} = useCurrentSlug();
  const {samplesAvailable} = useEnhanced(setId);
  const {drugName} = useCurrentDrug();
  const pdpIsEmbedded = useRenderInIFrame();

  const {
    patientEducation: showPE,
    priorAuthorization: showPA,
    financialAssistance: showFA,
  } = useSectionsStatus();

  const showSignUpDrugUpdates =
    useFlag(FLAGS.SIGNUP_DRUG_UPDATE) &&
    drugName === 'Cibinqo' &&
    !pdpIsEmbedded; // this is only being rendered for Cibinqo;

  const showHeaderV2 = useFlag(FLAGS.SHOW_V2_PPT_DESIGN);
  const showCTA = !pdpIsEmbedded && !showHeaderV2;
  const {showCoverageOnPdp} = useIsCoverageToolAvailable();

  const {data: interactionsData} = useInteractionsByDrug(setId, true);
  const showDDI = !!(useFlag(FLAGS.DDI_TOOL) && interactionsData?.totalItems);
  useAutoOpenChatBox();

  return (
    <div className={'w-full bg-neutral-white'}>
      <Header
        samples={samplesAvailable}
        coverage={showCoverageOnPdp}
        interactions={showDDI}
        financialAssistance={showFA}
        priorAuthorization={showPA}
        patientEducation={showPE}
      />

      <PageContentLayoutNew>
        <div
          className={clsx('min-h-page-content', {'px-6 pt-4': pdpIsEmbedded})}>
          {showSignUpDrugUpdates ? <SignUpForUpdates /> : null}
          <Sections />
        </div>
        {showCTA ? <CTAMenu /> : null}
      </PageContentLayoutNew>
    </div>
  );
};

export default memo(ProductDetail);
