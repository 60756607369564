import {Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC} from 'react';

export interface ICoverageDiscoverMoreCardProps {
  title: string;
  description: string;
  cta: string;
  urlImage: string;
  altImage: string;
  onClick?: () => void;
  className?: ClassValue;
  testId?: string;
}

const CoverageDiscoverMoreCard: FC<ICoverageDiscoverMoreCardProps> = ({
  title,
  description,
  cta,
  urlImage,
  altImage,
  onClick = () => {},
  className = '',
  testId = '',
}) => (
  <button
    data-testid={testId}
    className={clsx(
      'bg-neutral-white flex w-full items-center justify-start space-x-2 rounded-lg border border-solid border-neutral-quaternary p-4 no-underline hover:border-neutral-primary active:border-neutral-primary active:bg-neutral-lighter-alt',
      className,
    )}
    onClick={onClick}>
    {/* eslint-disable-next-line @next/next/no-img-element */}
    <img src={urlImage} alt={altImage} className='max-h-20 shrink-0' />
    <div className='items-between flex flex-col space-y-1 text-left'>
      <Text
        as='body-md'
        weight='extrabold'
        casing='title'
        className='!text-neutral-dark'>
        {title}
      </Text>
      <Text as='body-sm' weight='semibold' casing='sentence'>
        {description}
      </Text>
      <Text
        as='body-sm'
        weight='semibold'
        className='underline'
        casing='title'
        variant='black'>
        {cta}
      </Text>
    </div>
  </button>
);

export default CoverageDiscoverMoreCard;
