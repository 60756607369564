import {Icon, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC} from 'react';

export interface ICoveragePriorAuthRequirementsItemProps {
  title: string;
  description?: string;
  className?: string;
}

const CoveragePriorAuthRequirementsItem: FC<
  ICoveragePriorAuthRequirementsItemProps
> = ({title, description, className = ''}) => {
  const descriptionComplete = () => {
    if (description) {
      const regex = /^(=|>=|=>|=<|<=|>|<)/;
      if (regex.test(description)) {
        return ` ${description.trim()}`;
      } else {
        return ` = ${description.trim()}`;
      }
    }

    return '';
  };

  return (
    <div
      className={clsx('flex items-center justify-start space-x-3', className)}>
      <div className='flex h-4.5 w-4.5 shrink-0 items-center justify-center rounded-full'>
        <Icon
          name='CheckmarkFilled'
          className='flex w-2.5 items-center justify-center text-neutral-primary'
        />
      </div>
      <div>
        <Text
          as='body-sm'
          weight='bold'
          className='!text-neutral-darker'
          casing='title'>
          {title}
        </Text>
        {description ? (
          <Text
            as='body-xs'
            variant='secondary'
            casing='sentence'
            weight='semibold'>
            {descriptionComplete()}
          </Text>
        ) : null}
      </div>
    </div>
  );
};

export default CoveragePriorAuthRequirementsItem;
