import {
  Paper,
  FormInput,
  Button,
  Text,
  customEvent,
  Link,
} from '@prescriberpoint/ui';
import clsx from 'clsx';
import React, {FC, useCallback, useState} from 'react';
import InterstitialModal from '@/components/InterstitialModal';
import {
  CEVENT_COVERAGE_NPI_NUMBER_AUTH,
  CEVENT_NPI_LOOKUP_EXTERNAL,
  DlvCoverageNPINumberAuth,
} from '@/constants';
import {NPI_COOKIE} from '@/constants/cookies';
import {COVERAGE_V3} from '@/constants/flags';
import {useCoverageRestrictionsContext, useFlag} from '@/context';
import {useCurrentDrug, useHandleSetCookies} from '@/hooks';
import {useStepTherapyLogMutation} from '@/mutations';
import {npiCallback} from '@/utils/npi';
import {validateNpi} from '@/utils/validations';

export interface INPIInputProps {
  isLoading?: boolean;
  className?: string;
}

const NPI_REGISTRY_PAGE_URL = 'https://npiregistry.cms.hhs.gov/';

const NPIInput: FC<INPIInputProps> = ({isLoading = false, className}) => {
  const {submittedCoverage} = useCoverageRestrictionsContext();
  const {handleSetCookie} = useHandleSetCookies();
  const {mutate: stepTherapyLogMutate} = useStepTherapyLogMutation();
  const [value, setValue] = useState('');
  const isValid = validateNpi(value);
  const [pristine, setPristine] = useState(true);
  const {drugName} = useCurrentDrug();
  const [openModal, setOpenModal] = useState(false);
  const showCoverageV3 = useFlag(COVERAGE_V3);

  const handleSubmit = useCallback(() => {
    if (isValid) {
      const npi = value;
      handleSetCookie(NPI_COOKIE, npi, {path: '/'});
      npiCallback(npi);
      customEvent<DlvCoverageNPINumberAuth>(CEVENT_COVERAGE_NPI_NUMBER_AUTH, {
        npi_auth: npi,
      });
      stepTherapyLogMutate({
        ...submittedCoverage!,
        npi,
      });
    } else {
      setPristine(false);
    }
  }, [
    handleSetCookie,
    stepTherapyLogMutate,
    submittedCoverage,
    isValid,
    value,
  ]);

  const gotoNpiRegistryPage = () => {
    customEvent(CEVENT_NPI_LOOKUP_EXTERNAL);
  };

  return (
    <Paper
      className={clsx(
        'flex !h-fit flex-col space-y-3 rounded-xl bg-transparent md:h-[126px]',
        [
          showCoverageV3
            ? '!p-0'
            : 'bg-gradient-to-l from-[#B1FD9E] from-[11.88%] to-[#C9FB97] to-[95.93%] p-5',
        ],
        className,
      )}>
      <Text as='title-md' weight='extrabold'>
        See Step Therapies for {drugName}
      </Text>
      <div className='items-strech flex flex-col content-center space-y-3 md:flex-row md:space-x-4 md:space-y-0'>
        <FormInput.Text
          required
          id='npi_verify'
          testId='npi_input'
          name='npi'
          type='text'
          value={value}
          isValid={isValid || pristine}
          onChange={(v) => {
            setValue(v);
          }}
          disabled={isLoading}
          inputSize='lg'
          className={clsx('flex-none rounded-lg p-3 font-medium', [
            isLoading
              ? 'border-neutral-lighter bg-neutral-lighter'
              : 'placeholder:font-base border-neutral-tertiary-alt bg-white placeholder:leading-[26px] placeholder:tracking-[.25px] placeholder:text-neutral-tertiary',
          ], {
            '!border-error': !isValid && !pristine
          })}
          placeholder='Enter prescriber or practice NPI#'
          errorMessage={!isValid && !pristine ? 'NPI# can’t be blank' : null}
        />
        <Button
          onClick={handleSubmit}
          id='npi-btn'
          testId='npi_input_btn'
          className={clsx(
            {'!cursor-not-allowed': isLoading},
            {'text-white': !showCoverageV3},
            'w-full shrink-0 self-stretch px-8 py-3 md:w-fit',
          )}
          classNameText='text-base'
          variant='alternative'
          size='lg'
          disabled={isLoading}
          inProgress={isLoading}>
          See Step Therapies
        </Button>
      </div>
      <Link
        label="I don't know my prescriber's or practice's NPI#"
        type='underline'
        onClick={() => setOpenModal(true)}
        className='w-fit'
        textClassName='font-semibold'
        linkSize='lg'
      />

      <InterstitialModal
        isOpen={openModal}
        brandName='the hhs.gov'
        shareLink={NPI_REGISTRY_PAGE_URL}
        resourceName={'the NPI Registry'}
        confirmLeave={gotoNpiRegistryPage}
        goBack={() => setOpenModal(false)}
      />
    </Paper>
  );
};

export default NPIInput;
