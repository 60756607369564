import {ICoverageRestrictionsResponseModel} from '@/models/mmit';
import {read} from '@/services/api';

export interface ICoverageSearchParams {
  mco: number;
  planType: string;
  channel: string;
  indicationId: string;
  setId: string;
  rxcui: string;
}

export async function coverageSearch({
  mco,
  planType,
  channel,
  setId,
  indicationId,
  rxcui,
}: ICoverageSearchParams): Promise<ICoverageRestrictionsResponseModel | null> {
  let data = null;
  try {
    data = await read<ICoverageRestrictionsResponseModel>(
      'search',
      {
        parentName: 'coverage',
        parentId: mco.toString(),
        resourceId: setId,
        params: {
          channelId: channel,
          planTypeId: planType,
          indicationId,
          rxcui,
        },
      },
      {},
      'nextApi',
    );
  } catch (error) {}

  return data;
}
