import clsx from 'clsx';
import {FC, useMemo} from 'react';
import {useCookies} from 'react-cookie';
import CoverageReloadButton from '../CoverageReloadButton';
import CoverageDiscoverMore from './CoverageDiscoverMore';
import CoverageEmployerForm from './CoverageEmployerForm';
import CoverageNonResults from './CoverageNonResults';
import CoveragePriorAuthRequirements from './CoveragePriorAuthRequirements';
import CoverageSummarySearch from './CoverageSummarySearch';
import CoverageLoader from './CoverageSummarySearch/CoverageLoader';
import NPIInput from './NPIInput';
import StepTherapyStepList from './StepTherapyStepList';
import {NPI_COOKIE} from '@/constants/cookies';
import {COVERAGE_V3} from '@/constants/flags';
import {useCoverageRestrictionsContext, useFlag} from '@/context';

interface ICoverageBodyProps {
  onPdp: boolean;
}

const CoverageBody: FC<ICoverageBodyProps> = ({onPdp}) => {
  const [cookies, _] = useCookies([NPI_COOKIE]);
  const showCoverageV3 = useFlag(COVERAGE_V3);
  const npi = useMemo(() => cookies?.npi, [cookies]);
  const {result, isSubmitting} = useCoverageRestrictionsContext();

  const showCoverageEmployerForm = npi && showCoverageV3;

  const restrictions = useMemo(
    () => result?.carrier?.paRestrictions ?? [],
    [result],
  );

  const hasRestrictions = useMemo(
    () => restrictions.length !== 0,
    [restrictions],
  );

  const hasPA = useMemo(() => !!result?.hasPaRestrictions, [result]);
  const stepsTheraphy = useMemo(() => result?.carrier?.steps ?? [], [result]);

  const hasSteps = useMemo(() => {
    if (stepsTheraphy.length > 0) {
      return (
        stepsTheraphy[0].therapies[0].toLowerCase() !== 'not covered' &&
        stepsTheraphy[0].therapies[0].toLowerCase() !== 'no step'
      );
    }

    return false;
  }, [stepsTheraphy]);

  const notCovered = useMemo(() => {
    if (stepsTheraphy.length > 0) {
      return stepsTheraphy[0].therapies[0].toLowerCase() === 'not covered';
    }

    return false;
  }, [stepsTheraphy]);

  if (isSubmitting) {
    return <CoverageLoader duration={7000} />;
  }
  return (
    <div
      className={clsx('mx-auto flex w-full flex-col', {
        'md:max-w-[920px]': !onPdp,
      })}>
      <CoverageReloadButton onPdp={onPdp} />
      <CoverageSummarySearch onPdp={onPdp} />

      {result ? (
        <div className='mt-6 flex flex-col gap-y-7 md:gap-y-6'>
          <CoveragePriorAuthRequirements requirements={restrictions} />

          {npi ? (
            <>
              <CoverageNonResults
                hasRestrictions={hasRestrictions}
                hasSteps={hasSteps}
                hasPA={hasPA}
                notCovered={notCovered}
              />

              {hasSteps ? <StepTherapyStepList steps={stepsTheraphy} /> : null}

              {showCoverageEmployerForm ? (
                <CoverageEmployerForm onPdp={onPdp} />
              ) : null}

              <CoverageDiscoverMore
                hasRestrictions={hasRestrictions}
                hasPA={hasPA}
              />
            </>
          ) : (
            <NPIInput />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CoverageBody;
