import {CEVENT_NPI} from '@/constants';
import {IS_PRODUCTION} from '@/constants/global';
import {create} from '@/services/api';
import {customEvent, setVariable} from '@/utils/gtm';

export function npiCallback(npiNumber: string) {
  setVariable('npi', npiNumber);

  customEvent(CEVENT_NPI, {
    npi: npiNumber,
  });

  if (IS_PRODUCTION) {
    //@ts-ignore
    window?.clarity('set', 'npi_verified', 'true');
  }

  create(
    'NpiNotification',
    {parentName: 'User', params: {npi: npiNumber}},
    {},
    'profile',
  ).catch((e) => console.error(e));
}
