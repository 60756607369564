import {Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC, useEffect, useState} from 'react';

interface ITimedTextListProps {
  timeout: number;
  delay?: number;
  displayTexts: string[];
  className?: ClassValue;
}

const TimedTextList: FC<ITimedTextListProps> = ({
  delay = 0,
  timeout,
  displayTexts,
  className,
}) => {
  const [delayDone, setDelayDone] = useState(false);
  const [opacity, setOpacity] = useState('opacity-100');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelayDone(true);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (delayDone) {
      const intervalId = setInterval(() => {
        setOpacity('opacity-0');
        setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % displayTexts.length);
          setOpacity('opacity-100');
        }, 300); // wait for the previous text to disappear
      }, timeout);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [delayDone, timeout, displayTexts.length]);

  return (
    <Text
      variant="primary"
      className={clsx(
        'transition-opacity duration-300 ease-in',
        opacity,
        delayDone ? 'opacity-100' : 'opacity-0',
        className,
      )}
      weight="bold"
      size="md">
      {displayTexts[currentIndex]}
    </Text>
  );
};

export default TimedTextList;
