import {Loader} from '@prescriberpoint/ui';
import {ClassValue} from 'clsx';
import {FC} from 'react';
import TimedTextList from '@/components/TimedTextList';

const LOADING_TEXTS = [
  'Looking up insurance plan',
  'Pulling plan information',
  'Reviewing coverage info by indication',
  'Checking coverage info by dosage and strength',
  'Completing final accuracy check',
];

export interface ICoverageLoaderProps {
  className?: ClassValue;
  duration?: number;
  loadingTexts?: string[];
}

const CoverageLoader: FC<ICoverageLoaderProps> = ({
  className,
  duration = 5000,
  loadingTexts = LOADING_TEXTS,
}) => (
  <div className='flex w-full flex-col items-center gap-y-4'>
    <Loader className={className} />
    <div className='px-8 py-3 text-center'>
      <TimedTextList
        timeout={duration / loadingTexts.length}
        displayTexts={loadingTexts}
      />
    </div>
  </div>
);

export default CoverageLoader;
