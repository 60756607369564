import {Button, customEvent, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {
  CEVENT_COVERAGE_MCO_PRE_SELECTED,
  CEVENT_COVERAGE_MCO_SELECTED,
} from '@/constants';
import {MCO_QUICK_PICKS_OPTIONS} from '@/constants/coverage';
import {MCO_QUICK_PICKS} from '@/constants/flags';
import {useCoverageRestrictionsContext, useFlag} from '@/context';
import {useCurrentDrug} from '@/hooks';

interface McoQuickPicksProps {
  highlighted?: boolean;
  onPdp?: boolean;
}

const McoQuickPicks = ({
  highlighted = false,
  onPdp = false,
}: McoQuickPicksProps) => {
  const {drugName} = useCurrentDrug();
  const mcoQuickPicksEnabled = useFlag(MCO_QUICK_PICKS);

  const {setHealthPlan, setShowForm, showForm} =
    useCoverageRestrictionsContext();

  if (!mcoQuickPicksEnabled || showForm) {
    return null;
  }

  return (
    <div
      className={clsx('flex w-full flex-col items-center md:p-6 md:pt-0', {
        'md:pb-0': highlighted || onPdp,
        'space-y-5': !onPdp,
        'space-y-2 md:space-y-5': onPdp,
      })}>
      <Text as='body-md' weight='regular' className='text-center'>
        Or select your Insurance from the list below:
      </Text>
      <div
        className={clsx(
          'flex flex-wrap justify-center gap-4 md:max-w-[857px] md:gap-3',
          {
            '!md:max-w-[1000px]': onPdp,
          },
        )}>
        {MCO_QUICK_PICKS_OPTIONS.map(({id, value}) => (
          <Button
            key={id}
            id={`mco-quick-pick-${id}`}
            variant='secondary'
            onClick={() => {
              setHealthPlan({id, value});
              setShowForm(true);

              customEvent(CEVENT_COVERAGE_MCO_SELECTED, {
                drugBrand: drugName,
                mco: value,
              });
              customEvent(CEVENT_COVERAGE_MCO_PRE_SELECTED, {
                drugBrand: drugName,
                mco: value,
              });
            }}>
            {value}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default McoQuickPicks;
