import {Text, Loader, Link} from '@prescriberpoint/ui';
import React, {useEffect, useMemo} from 'react';
import CoverageLoader from '../CoverageLoader';
import Form from './Form';
import {
  CEVENT_COVERAGE_CHANNEL_SELECTED,
  CEVENT_COVERAGE_INSURANCE_TYPE_SELECTED,
} from '@/constants';
import {COVERAGE_V3} from '@/constants/flags';
import {
  useCoverageRestrictionsContext,
  useFlag,
  useUserAgentContext,
} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {useCoverageFilters} from '@/queries';
import {useMCOList} from '@/queries/useMCOList';
import {customEvent} from '@/utils/gtm';

interface ICoveragePayerFormProps {
  onPdp?: boolean;
}

const CoveragePayerForm: React.FC<ICoveragePayerFormProps> = ({
  onPdp = false,
}) => {
  const {drugName} = useCurrentDrug();
  const showCoverageV3 = useFlag(COVERAGE_V3);
  const {
    isLoadingPatientIndication,
    result,
    selectedIndication,
    selectedDosage,
    coverageRestrictionTitle,
    formExpanded,
    setFormExpanded,
    isSubmitting,
    planType,
    healthPlan,
    setPlanType,
    insuranceChannel,
    setInsuranceChannel,
    resetInsuranceForm,
  } = useCoverageRestrictionsContext();

  const collapsedText = showCoverageV3 ? 'Try another search' : 'Edit details';
  const {isFetching: mcoListLoading} = useMCOList();
  const {data: coverageFilters} = useCoverageFilters(`${healthPlan?.id}`, {
    enabled: !!healthPlan,
  });

  const channelFilters = useMemo(
    () => coverageFilters?.filters,
    [coverageFilters],
  );

  const planTypeFilters = useMemo(
    () =>
      coverageFilters?.filters?.find(
        (filter) => filter.key === insuranceChannel?.key,
      )?.plans,
    [coverageFilters, insuranceChannel],
  );

  const {isMobileOrTablet} = useUserAgentContext();

  useEffect(() => {
    if (channelFilters?.length === 1) {
      setInsuranceChannel(channelFilters[0]);

      customEvent(CEVENT_COVERAGE_CHANNEL_SELECTED, {
        drugBrand: drugName,
        channel: channelFilters[0].text,
        mco: healthPlan?.value,
      });
    }
  }, [channelFilters, drugName, healthPlan?.value, setInsuranceChannel]);

  useEffect(() => {
    if (planTypeFilters?.length === 1) {
      setPlanType(planTypeFilters[0]);

      customEvent(CEVENT_COVERAGE_INSURANCE_TYPE_SELECTED, {
        drugBrand: drugName,
        mco: healthPlan?.value,
        channel: insuranceChannel?.text,
        planType: planTypeFilters[0]?.text,
      });
    }
  }, [
    planTypeFilters,
    planType,
    drugName,
    insuranceChannel?.text,
    healthPlan?.value,
    setPlanType,
  ]);

  useEffect(() => {
    if (!selectedIndication && !selectedDosage && !isLoadingPatientIndication) {
      resetInsuranceForm();
    }
  }, [
    selectedIndication,
    selectedDosage,
    isLoadingPatientIndication,
    resetInsuranceForm,
  ]);

  if (mcoListLoading) {
    return (
      <div className='flex h-full w-full content-center justify-center'>
        <Loader spinnerSize='sm' />
      </div>
    );
  }

  if (isSubmitting) {
    return <CoverageLoader duration={7000} />;
  }

  return (
    <>
      {result ? (
        <div className='flex flex-col'>
          <Text
            as={isMobileOrTablet ? 'headline-sm' : 'headline-md'}
            weight='bold'>
            {`${drugName} for ${selectedIndication!.text} (${
              selectedDosage!.text
            })`}
          </Text>
          <Text as='body-md' weight='semibold'>
            {coverageRestrictionTitle}
          </Text>
        </div>
      ) : null}
      {formExpanded ? <Form onPdp={onPdp}/> : null}
      {result ? (
        <Link
          id='details_action_button'
          data-testid='details_action_button'
          label={formExpanded ? 'Collapse details' : collapsedText}
          type='underline'
          onClick={() => setFormExpanded(!formExpanded)}
          className='!mt-3 block'
          textClassName='font-semibold'
          linkSize='xl'
        />
      ) : null}
    </>
  );
};

export default CoveragePayerForm;
