import {ComboBox, customEvent, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useCallback, useMemo} from 'react';
import {
  CEVENT_COVERAGE_EMPLOYER_INPUT,
  CEVENT_COVERAGE_EMPLOYER_SELECTED,
} from '@/constants';
import {useCoverageRestrictionsContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {useEmployers} from '@/queries';

const CoverageEmployerInput: FC = () => {
  const {drugName} = useCurrentDrug();
  const {
    isSubmitting,
    reset,
    query,
    setQuery,
    selectedEmployer,
    setSelectedEmployer,
    firstInputChange,
    setFirstInputChange,
    formErrors,
    resetFormErrors,
  } = useCoverageRestrictionsContext();

  const {data: employers = [], isFetching} = useEmployers(query);

  const handleQueryChange = (query: string) => {
    resetFormErrors();
    setQuery(query);
    if (query.length === 1 && firstInputChange) {
      setFirstInputChange(false);
      customEvent(CEVENT_COVERAGE_EMPLOYER_INPUT, {
        drugBrand: drugName,
      });
    }

    if (selectedEmployer) {
      setSelectedEmployer(null);
    }
  };

  const onEmployerChange = useCallback(
    (employer: any) => {
      if (employer) {
        setSelectedEmployer(employer);
        customEvent(CEVENT_COVERAGE_EMPLOYER_SELECTED, {
          drugBrand: drugName,
          employerName: employer,
        });
      }
    },
    [drugName, setSelectedEmployer],
  );

  const options = useMemo(() => {
    if (isFetching) return [];
    return employers;
  }, [employers, isFetching]);

  const error = formErrors?.employer;

  return (
    <div className='flex w-full flex-col space-y-1'>
      <ComboBox
        id='employer-list-search'
        placeholder='Enter employer name (e.g. Amazon)'
        selectedItem={selectedEmployer}
        options={options}
        size='lg'
        showNoMatchText={isFetching}
        dropdownClassName='z-max !max-h-[200px]'
        clearOnNoMatch={false}
        enableFiltering={false}
        isLoading={isFetching}
        showCancelSearch={false}
        onCancelClick={reset}
        onQueryChange={handleQueryChange}
        onChange={onEmployerChange}
        disabled={isSubmitting}
        inputContainerClassName={clsx({'border-error': error})}
      />
      {error && (
        <Text className='text-error' weight='medium' size='xs'>
          {error}
        </Text>
      )}
    </div>
  );
};

export default CoverageEmployerInput;
