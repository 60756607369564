import {HighlightSectionType, IHighlightSectionText} from '../models';

export const HIGHLIGHT_SECTION_TEXTS: Record<
  HighlightSectionType,
  IHighlightSectionText
> = {
  samples: {
    title: 'The Easy Way To Get Samples',
    description: 'Connect with your reps & request samples.',
    cta: 'Get Samples',
  },
  coverage: {
    title: 'Check Coverage Restrictions',
    description: '',
    cta: 'Check Now',
  },
  interactions: {
    title: 'Check Drug Interactions',
    description: 'Check Known drug interactions.',
    cta: 'Check Drug Interactions',
  },
  financialAssistance: {
    title: '',
    description: '',
    cta: '',
  },
  priorAuthorization: {
    title: '',
    description: '',
    cta: '',
  },
  patientEducation: {
    title: '',
    description: '',
    cta: '',
  },
};
