import {Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import CoverageNonResultItem from './CoverageNonResultItem';
import {useCoverageRestrictionsContext} from '@/context';

interface ICoverageNonResultsProps {
  hasRestrictions: boolean;
  hasPA: boolean;
  hasSteps: boolean;
  notCovered: boolean;
}

const CoverageNonResults: FC<ICoverageNonResultsProps> = ({
  hasRestrictions,
  hasPA,
  hasSteps,
  notCovered,
}) => {
  const {coverageRestrictionTitle} = useCoverageRestrictionsContext();

  const noStepItem = () => (
    <CoverageNonResultItem
      text="No documented and/or required coverage restrictions or step
    therapies for this combination."
    />
  );

  if (!hasRestrictions) {
    return (
      <div className="flex flex-col space-y-3">
        <Text as="title-lg" weight="extrabold">
          Requirements
        </Text>
        <CoverageNonResultItem
          text={
            hasPA
              ? 'Prior authorization is required for this insurance, indication, and dosage combination.'
              : 'No documented and/or required prior authorization for this combination.'
          }
          error={!hasPA}
        />
        {!hasSteps ? noStepItem() : null}
      </div>
    );
  }

  if (!hasSteps) {
    return (
      <>
        <Text as="title-lg" weight="extrabold">
          Step Therapy
        </Text>
        {noStepItem()}
      </>
    );
  }

  return notCovered ? (
    <Text as="title-lg" weight="extrabold">
      {coverageRestrictionTitle} does not provide coverage for this drug.
    </Text>
  ) : null;
};

export default CoverageNonResults;
