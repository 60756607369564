import {ClassValue} from 'clsx';
import {useRouter} from 'next/router';
import {FC} from 'react';
import CoverageDiscoverMoreCard from './CoverageDiscoverMoreCard';
import {CEVENT_COVERAGE_NEXT_PRIORAUTH} from '@/constants';
import {DISCOVER_MORE_PA_IMAGE_URL} from '@/constants/discoverMoreCard';
import {useCurrentDrug} from '@/hooks';
import {customEvent} from '@/utils/gtm';

export interface ICoverageDiscoverMoreCardFAProps {
  className?: ClassValue;
  withPA?: boolean;
}

const CoverageDiscoverMoreCardPA: FC<ICoverageDiscoverMoreCardFAProps> = ({
  className,
  withPA = true,
}) => {
  const {slug} = useCurrentDrug();
  const router = useRouter();

  const handleGetPriorAuthForms = () => {
    if (slug) {
      if (router.pathname.includes('/therapies')) {
        // Need to call pivot logic due to the pivot menu having conflicts with
        // outside anchor scrolls. Important: when removing pivot menu update this logic!!!
        document.getElementById(`ss_pivot_prior_authorization`)?.click();
      } else {
        const url = '/therapies/' + slug + '#prior_authorization';
        window.open(`${window.location.origin}/${url}`, '_blank');
      }
      customEvent(CEVENT_COVERAGE_NEXT_PRIORAUTH);
    }
  };

  const priorAuthTitle = withPA
    ? 'Prior Authorization Forms'
    : 'Formulary Exception';

  const priorAuthCta = withPA ? 'Download' : 'Get Form';

  return (
    <CoverageDiscoverMoreCard
      testId='mock-coverage-pa'
      title={priorAuthTitle}
      description=''
      cta={priorAuthCta}
      urlImage={DISCOVER_MORE_PA_IMAGE_URL}
      altImage={priorAuthTitle}
      onClick={handleGetPriorAuthForms}
      className={className}
    />
  );
};

export default CoverageDiscoverMoreCardPA;
