import {Button, useIsMobileOrTablet} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {XIcon} from 'lucide-react';
import {FC} from 'react';
import {useCoverageRestrictionsContext} from '@/context';

interface ICoverageReloadButtonProps {
  className?: ClassValue;
  onPdp?: boolean;
}

const CoverageReloadButton: FC<ICoverageReloadButtonProps> = ({
  className,
  onPdp = false,
}) => {
  const {result, setShowCoverageModalReload} = useCoverageRestrictionsContext();
  const isMobileOrTablet = useIsMobileOrTablet();

  const handleOnClick = () => {
    setShowCoverageModalReload(true);
  };

  if (!result || (!onPdp && isMobileOrTablet)) {
    return null;
  }

  return (
    <Button
      variant='text'
      size='lg'
      iconLeft={<XIcon size={20} />}
      id='coverage-back'
      data-testid='coverage-back-btn'
      className={clsx('mb-5 gap-4 p-0 active:bg-transparent', className)}
      classNameText='font-semibold text-neutral-primary'
      classNameIconLeft='w-5 h-5'
      onClick={handleOnClick}>
      Close
    </Button>
  );
};

export default CoverageReloadButton;
