import {
  Icon,
  Text,
  CTAForm,
  Button,
  useIsMobileOrTablet,
  Portal,
  IconButton,
  customEvent,
} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {useCallback, useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useEffectOnce} from 'usehooks-ts';
import AgreeTermsAndCondtitionsAndPrivacyPolicy from '../Auth/AddEmail/AgreeTermsAndCondtitionsAndPrivacyPolicy';
import {CEVENT_SIGNUP_FOR_UPDATES, DlvSignupForUpdates} from '@/constants';
import {useCurrentDrug, useEnhanced, useError, useIsOtc} from '@/hooks';
import {useSignUpForUpdates} from '@/mutations';
import {useLabel} from '@/queries';
import {APIError} from '@/services/request';
import {getEmailDomain} from '@/utils/gtm';
import {validateEmail} from '@/utils/validations';

const SignUpForUpdates = () => {
  const [cookies] = useCookies(['hubspotutk']);
  const [showForm, setShowForm] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const {drugName, labelerName, setId} = useCurrentDrug();
  const {isEnhanced, enhancedContent} = useEnhanced(setId);
  const {data: label} = useLabel(setId);
  const {
    mutateAsync: sendEmail,
    isPending: isLoading,
    error,
  } = useSignUpForUpdates();
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const isMobileOrTablet = useIsMobileOrTablet();
  const isOTC = useIsOtc();
  const {errorMsg} = useError(error as APIError | undefined);

  const scrollHandler = useCallback(() => {
    const pdpWrapper = document.scrollingElement!;

    const hasScrolledToBottom =
      Math.floor(pdpWrapper.scrollHeight - pdpWrapper.scrollTop) <
      pdpWrapper.clientHeight + 400;

    setHasScrolledToBottom(hasScrolledToBottom);
  }, []);

  useEffectOnce(() => {
    if (!isMobileOrTablet) {
      setShowForm(true);
    }
  });

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, true);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  const handleToggleForm = () => {
    setShowForm(!showForm);
    if (submittedSuccessfully) {
      setTimeout(() => {
        setSubmittedSuccessfully(false);
      }, 1000);
    }
  };

  const isEnhancedType = isEnhanced ? 'enhanced' : 'non-enhanced';

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        await sendEmail({
          ...data,
          drugName,
          hubspotCookie: cookies.hubspotutk,
        });
        setSubmittedSuccessfully(true);
        customEvent<DlvSignupForUpdates>(CEVENT_SIGNUP_FOR_UPDATES, {
          emailDomain: getEmailDomain(data.email as string),
          drugName,
          pharmaCompany: labelerName,
          isEnhanced: isOTC ? 'otc' : isEnhancedType,
          drugType: label?.metadata?.generic === 'true' ? 'generic' : 'brand',
          drugSpecialties: isEnhanced
            ? enhancedContent?.therapeuticAreas.toString()!
            : 'n/a',
          setId,
        });
      } catch (error: any) {
        console.error(error);
      }
    },
    [
      sendEmail,
      drugName,
      cookies.hubspotutk,
      labelerName,
      isOTC,
      isEnhancedType,
      label?.metadata?.generic,
      isEnhanced,
      enhancedContent?.therapeuticAreas,
      setId,
    ],
  );

  return (
    <div className={clsx('absolute', {hidden: hasScrolledToBottom})}>
      {!showForm ? (
        <button
          className='fixed bottom-32 left-5 z-max mb-4 flex items-center justify-center bg-transparent md:bottom-24 md:left-6'
          onClick={handleToggleForm}>
          <div className='relative -translate-x-1/2 rotate-90 transform cursor-pointer rounded-t-md bg-white p-3 shadow-lg md:p-4'>
            <div className='absolute left-1/2 top-0 flex h-5 w-5 -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-theme-primary md:h-[26px] md:w-[26px]'>
              <Icon
                name='Arrow'
                className='h-4 w-4 rotate-180 text-white md:h-5 md:w-5'
              />
            </div>
            <Text className='tracking-wide' weight='black'>
              {isMobileOrTablet ? 'Get Updates' : 'Sign Up For Updates'}
            </Text>
          </div>
        </button>
      ) : (
        <Portal>
          <div className='fixed left-0 top-0 h-screen w-screen bg-black bg-opacity-20 md:bg-transparent'>
            <div className='fixed bottom-1/2 left-1/2 z-max w-full max-w-[90vw] -translate-x-1/2 translate-y-1/2 rounded-md bg-white p-6 shadow-md md:bottom-10 md:left-10 md:max-w-[520px] md:translate-x-0 md:translate-y-0'>
              {submittedSuccessfully ? (
                <div className='flex flex-col space-y-4'>
                  <Text>{`Thanks! We'll notify you of any new information regarding ${drugName}.`}</Text>
                  <Button id='success_close_modal' onClick={handleToggleForm}>
                    Close
                  </Button>
                </div>
              ) : (
                <>
                  <div className='absolute right-4 top-4'>
                    <IconButton
                      className='!h-7 !w-7 [&>span]:hover:text-theme-primary'
                      size='sm'
                      onClick={handleToggleForm}
                      id='cancel_close_modal'
                      icon={<Icon name='Cancel' className='flex h-3 w-3' />}
                    />
                  </div>
                  <Text className='mb-4 block tracking-wide' weight='black'>
                    Sign Up For {drugName} Updates
                  </Text>
                  <CTAForm
                    form={{
                      submitText: 'Sign Up',
                      isSubmittingText: 'Submitting...',
                      inputs: [
                        {
                          id: 'email',
                          type: 'email',
                          label: '',
                          required: false,
                          placeholder: `Email Address`,
                          errorMessage: `Please enter a valid email`,
                          disabled: false,
                          validateFn: (value: string) => validateEmail(value),
                        },
                      ],
                    }}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    submitError={!!error}
                    submitErrorMsg={errorMsg}
                  />
                  <div className='mt-4'>
                    <AgreeTermsAndCondtitionsAndPrivacyPolicy />
                  </div>
                </>
              )}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default SignUpForUpdates;
