import {IComboBoxOption} from '@prescriberpoint/ui';
import {FC, useCallback, useMemo, useRef} from 'react';
import CoverageComboBox from '@/components/CoverageComboBox';
import {MCO_QUICK_PICKS_OPTIONS} from '@/constants';
import {
  CEVENT_COVERAGE_MCO_INPUT,
  CEVENT_COVERAGE_MCO_SELECTED,
} from '@/constants/gtm';
import {useUserAgentContext} from '@/context';
import {useCoverageRestrictionsContext} from '@/context/CoverageRestrictionsContext';
import {useCurrentDrug} from '@/hooks';
import {useMCOList} from '@/queries';
import {customEvent} from '@/utils/gtm';

interface IFirstStepProps {
  onPdp?: boolean;
}

const FirstStep: FC<IFirstStepProps> = ({onPdp}) => {
  const {
    isSubmitting,
    firstInputChange,
    setFirstInputChange,
    healthPlan,
    setHealthPlan,
    setPlanType,
    setInsuranceChannel,
    setCoverageRestrictionTitle,
    resetInsuranceForm,
    setShowForm,
    query,
    setQuery,
    showForm,
    resetFormErrors,
  } = useCoverageRestrictionsContext();

  const {isMobileOrTablet} = useUserAgentContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const {drugName} = useCurrentDrug();

  const {data: mcoList} = useMCOList();

  const handleHealthPlanQueryChange = (_query: string) => {
    setQuery(_query);
    if (_query.length === 1 && firstInputChange) {
      setFirstInputChange(false);
      customEvent(CEVENT_COVERAGE_MCO_INPUT, {
        drugBrand: drugName,
      });
    }
  };

  const onHealthPlanChange = useCallback(
    (value: IComboBoxOption | null) => {
      if (!value) {
        resetInsuranceForm();
      } else {
        setHealthPlan(value);
        setShowForm(true);

        customEvent(CEVENT_COVERAGE_MCO_SELECTED, {
          drugBrand: drugName,
          mco: value.value,
        });

        if (value.id !== healthPlan?.id) {
          setPlanType(undefined);
          setInsuranceChannel(undefined);
          setCoverageRestrictionTitle('');
          resetFormErrors();
        }
      }
    },
    [
      resetInsuranceForm,
      setHealthPlan,
      setShowForm,
      drugName,
      healthPlan?.id,
      setPlanType,
      setInsuranceChannel,
      setCoverageRestrictionTitle,
      resetFormErrors,
    ],
  );

  const healthPlanOptions = useMemo(() => {
    if (query.length === 0 && !healthPlan) {
      return MCO_QUICK_PICKS_OPTIONS;
    } else {
      return mcoList ?? [];
    }
  }, [mcoList, healthPlan, query]);

  return (
    <div className='flex flex-col justify-between gap-4 md:flex-row'>
      <CoverageComboBox
        id='mco-list-search'
        selectedItem={healthPlan}
        optionsHeader='Select your Insurance'
        onChange={onHealthPlanChange}
        openOnFocus={!isMobileOrTablet}
        showCancelSearch
        options={healthPlanOptions}
        placeholder='Enter Insurance Carrier (e.g. Anthem, Humana, etc.)'
        dropdownClassName='z-max !max-h-[328px]'
        containerClassName='flex-1'
        className='!mt-0 h-full w-full'
        inputRef={inputRef}
        disabled={isSubmitting}
        onQueryChange={handleHealthPlanQueryChange}
        size='lg'
        showLabel={showForm || isMobileOrTablet}
        isHighlitedCoverage
        onPdp={onPdp}
      />
    </div>
  );
};

export default FirstStep;
