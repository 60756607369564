import {Button, Modal, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {useEffect, useState} from 'react';
import Coverage from '..';
import {useCoverageRestrictionsContext, useUserAgentContext} from '@/context';

interface ResponsiveCoverageWrapperProps {
  highlighted?: boolean;
  showSeparator?: boolean;
}

const ResponsiveCoverageWrapper = ({
  highlighted = false,
  showSeparator = true,
}: Readonly<ResponsiveCoverageWrapperProps>) => {
  const {isMobileOrTablet} = useUserAgentContext();

  if (isMobileOrTablet) {
    return <MobileCoverageWrapper highlighted={highlighted} />;
  }

  return <Coverage highlighted={highlighted} showSeparator={showSeparator} />;
};

function MobileCoverageWrapper({
  highlighted = false,
}: Readonly<ResponsiveCoverageWrapperProps>) {
  const [isCoverageOpen, setIsCoverageOpen] = useState(false);
  const {result, setShowCoverageModalReload} = useCoverageRestrictionsContext();

  useEffect(() => {
    if (isCoverageOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup function to reset the overflow style when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [isCoverageOpen]);

  return (
    <>
      <div
        className={clsx('mx-auto flex flex-col space-y-5', {
          'mt-6': highlighted,
        })}>
        <div className='flex flex-col space-y-2 text-center'>
          <Text as='headline-sm' weight='bold'>
            Check Coverage Restriction
          </Text>
          <Text as='body-md'>Any insurance, any indication, any dosage</Text>
        </div>
        <Button
          id='coverage-check-now'
          className='w-full'
          size='lg'
          onClick={() => setIsCoverageOpen(true)}>
          Check Now
        </Button>
      </div>
      <Modal
        isOpen={isCoverageOpen}
        closeModal={() => setIsCoverageOpen(false)}
        showCloseButton={Boolean(!result)}
        position='side'
        wrapperClassName='!pt-0 min-h-dvh'
        bodyClassName='!pt-0 !px-5'
        className='min-h-dvh rounded-none'
        headerClassName='!pl-3'
        showConfirm={false}
        showCancel={false}
        showGoBack={Boolean(result)}
        onGoBack={() => setShowCoverageModalReload(true)}>
        <Coverage />
      </Modal>
    </>
  );
}

export default ResponsiveCoverageWrapper;
